var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.examNotes,"sort-by":"date"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatDate(item.date))}})]}},{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.trimPreview(item.summary === '' ? item.notes : item.summary)
          )}})]}},{key:"item.view",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" open_in_new ")])]}}])})],1),_c('v-container',[_c('v-bottom-sheet',{attrs:{"scrollable":"","hide-overlay":""},model:{value:(_vm.showNotes),callback:function ($$v) {_vm.showNotes=$$v},expression:"showNotes"}},[(_vm.selectedNotes)?_c('v-card',{staticClass:"text--black"},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red","small":""},on:{"click":function($event){_vm.showNotes = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.printPdf()}}},[_vm._v("Save Exam Summary "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download")])],1)],1),(_vm.selectedNotes.summary !== '')?_c('v-card-text',{staticClass:"black--text",attrs:{"id":"notesContent"},domProps:{"innerHTML":_vm._s(_vm.selectedNotes.header + _vm.selectedNotes.summary)}}):_c('v-card-text',{staticClass:"black--text",attrs:{"id":"notesContent"},domProps:{"innerHTML":_vm._s(_vm.selectedNotes.header + _vm.selectedNotes.notes)}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }