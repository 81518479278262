var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"exam-page"}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$mq === 'mobile' ? '6' : ''}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-heart-pulse")]),(_vm.appointment.vitals.pulse !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.appointment.vitals.pulse)+" "),_c('span',{staticClass:"caption"},[_vm._v("bpm")])]):_c('div',[_vm._v("--")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$mq === 'mobile' ? '6' : ''}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue"}},[_vm._v("mdi-battery-heart-outline")]),(_vm.appointment.vitals.pulseox !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.appointment.vitals.pulseox)+" "),_c('span',{staticClass:"caption"},[_vm._v("%")])]):_c('div',[_vm._v("--")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$mq === 'mobile' ? '6' : ''}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"pink"}},[_vm._v("mdi-lungs")]),(_vm.appointment.vitals.resprate !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.appointment.vitals.resprate)+" "),_c('span',{staticClass:"caption"},[_vm._v("bths/min")])]):_c('div',[_vm._v("--")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$mq === 'mobile' ? '6' : ''}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-thermometer")]),(_vm.appointment.vitals.temperature !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.appointment.vitals.temperature)+" "),_c('span',{staticClass:"caption"},[_vm._v("° F")])]):_c('div',[_vm._v("--")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$mq === 'mobile' ? '6' : ''}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi-heart")]),(
              _vm.appointment.vitals.systolic !== null &&
                _vm.appointment.vitals.diastolic !== null &&
                _vm.appointment.vitals.systolic &&
                _vm.appointment.vitals.diastolic
            )?_c('div',[_vm._v(" "+_vm._s(_vm.appointment.vitals.systolic)+" "),_c('span',[_vm._v("/")]),_vm._v(" "+_vm._s(_vm.appointment.vitals.diastolic)+" "),_c('span',{staticClass:"caption"},[_vm._v("mmHg")])]):_c('div',[_vm._v("--")])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$mq === 'mobile' ? '6' : ''}},[_c('v-card',{attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v("mdi-emoticon-happy-outline")]),(_vm.appointment.vitals.painscale !== null)?_c('div',[_vm._v(" "+_vm._s(_vm.appointment.vitals.painscale)+" ")]):_c('div',[_vm._v("--")])],1)],1)],1)],1),_c('v-card',{staticClass:"mt-4 mb-2",attrs:{"rounded":"lg"}},[_c('v-card-title',[_vm._v("Medical History")]),(
        _vm.medicalHistory &&
          _vm.appointment.medicalHistory.length > 0 &&
          _vm.appointment.medicalHistoryQuestions
      )?_c('v-card-text',{staticClass:"black--text",attrs:{"id":"medicalHistory"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.medicalHistory,"item-key":"index","items-per-page":_vm.itemsPerPage,"hide-default-footer":""}})],1):(_vm.appointment.medicalHistory.length > 0)?_c('v-card-text',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.appointment.medicalHistory)+" ")]):_c('v-card-text',[_vm._v("Medical history unavailable")])],1),_c('v-card',{staticClass:"mt-4",attrs:{"rounded":"lg"}},[_c('v-card-title',[_vm._v("Symptoms")]),(_vm.symptoms.length > 0)?_c('v-card-text',{staticClass:"black--text",attrs:{"id":"symptoms"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers2,"items":_vm.symptoms,"item-key":"index","items-per-page":_vm.itemsPerPage,"hide-default-footer":""}})],1):_c('v-card-text',[_vm._v("No symptoms")])],1),_c('Documents',{attrs:{"patientId":_vm.patientId,"uniqueName":_vm.uniqueName,"practiceId":_vm.practiceId}}),_c('Medications',{attrs:{"patientId":_vm.patientId,"uniqueName":_vm.uniqueName,"practiceId":_vm.practiceId}}),_c('Recordings',{attrs:{"patientId":_vm.patientId,"uniqueName":_vm.uniqueName,"practiceId":_vm.practiceId},on:{"ready":function($event){_vm.loadHealthData = true}}}),(_vm.loadHealthData)?_c('HealthData',{staticClass:"mb-8",attrs:{"patientId":_vm.patientId,"uniqueName":_vm.uniqueName,"practiceId":_vm.practiceId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }